@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 20 14.3% 4.1%;
        --card: 0 0% 100%;
        --card-foreground: 20 14.3% 4.1%;
        --popover: 0 0% 100%;
        --popover-foreground: 20 14.3% 4.1%;
        --primary: 24.6 95% 53.1%;
        --primary-foreground: 60 9.1% 97.8%;
        --success: 142.1 76.2% 36.3%;
        --success-foreground: 355.7 100% 97.3%;
        --secondary: 60 4.8% 95.9%;
        --secondary-foreground: 24 9.8% 10%;
        --muted: 60 4.8% 95.9%;
        --muted-foreground: 25 5.3% 44.7%;
        --accent: 60 4.8% 95.9%;
        --accent-foreground: 24 9.8% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 60 9.1% 97.8%;
        --border: 20 5.9% 90%;
        --input: 20 5.9% 90%;
        --ring: 24.6 95% 53.1%;
        --radius: 0.5rem;
        --shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }

    .dark {
        --background: 20 14.3% 4.1%;
        --foreground: 60 9.1% 97.8%;
        --card: 20 14.3% 4.1%;
        --card-foreground: 60 9.1% 97.8%;
        --popover: 20 14.3% 4.1%;
        --popover-foreground: 60 9.1% 97.8%;
        --primary: 20.5 90.2% 48.2%;
        --primary-foreground: 60 9.1% 97.8%;
        --secondary: 12 6.5% 15.1%;
        --secondary-foreground: 60 9.1% 97.8%;
        --muted: 12 6.5% 15.1%;
        --muted-foreground: 24 5.4% 63.9%;
        --accent: 12 6.5% 15.1%;
        --accent-foreground: 60 9.1% 97.8%;
        --destructive: 0 72.2% 50.6%;
        --destructive-foreground: 60 9.1% 97.8%;
        --border: 12 6.5% 15.1%;
        --input: 12 6.5% 15.1%;
        --ring: 20.5 90.2% 48.2%;
        --shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}